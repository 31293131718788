import React from 'react';
import HeroBlock from 'blocks/Hero';
import BlockPage from 'layout/components/BlockPage';
import BlockSection from 'sections/Block';
import Layout from 'components/Layout';

class DefaultPage extends BlockPage {
  constructor(props) {
    super(props);
  }

  renderChildren() {
    const { content } = this.props;

    return (
      <>
        {content && (
          <>
            {content.title && (
              <>
                <BlockSection isFirstSection={true} pageHasAnnouncement={this.pageHasAnnouncement()} color="lime">
                  <HeroBlock
                    title={content.title}
                    center={true}
                    leadingParagraph={content.pageOptions && content.pageOptions.leadingparagraph}
                    returnURL={content.ancestors && content.ancestors.length && content.ancestors[0].link}
                    returnLabel={content.ancestors && content.ancestors.length && content.ancestors[0].title}
                  />
                </BlockSection>
              </>
            )}
            <BlockSection isFirstSection={!content.title} pageHasAnnouncement={this.pageHasAnnouncement()}>
              <Layout.PageContainer hasSidebar={false}>
                <Layout.Content>
                  <Layout.LayoutInner>
                    {content.flexibleContent &&
                      content.flexibleContent.contentBlocks &&
                      content.flexibleContent.contentBlocks.map((contentBlock, index) => {
                        return (
                          <BlockSection key={index}>
                            {this.renderBlock(contentBlock, index)}
                          </BlockSection>
                        );
                      })}
                  </Layout.LayoutInner>
                </Layout.Content>
              </Layout.PageContainer>
            </BlockSection>
          </>
        )}
      </>
    );
  }
}

export default DefaultPage;
