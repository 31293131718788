import { graphql } from 'gatsby';
import React from 'react';
import Page from '.';
import { PageDataType } from 'types/Page';

interface Props {
  data: PageDataType;
}

const PageTemplate: React.StatelessComponent<Props> = ({ data }) => <Page {...data} />;

export default PageTemplate;

export const query = graphql`
  query($id: String!, $headerMenu: WpMenuLocationEnum!, $mainMenu: WpMenuLocationEnum!, $footerMenu: WpMenuLocationEnum!, $secondaryFooterMenu: WpMenuLocationEnum!) {
    headerMenu: wpMenu(locations: { eq: $headerMenu }) {
        ...Menu
      }
      mainMenu: wpMenu(locations: { eq: $mainMenu }) {
        ...Menu
      }
      footerMenu: wpMenu(locations: { eq: $footerMenu }) {
        ...Menu
      }
      secondaryFooterMenu: wpMenu(locations: { eq: $secondaryFooterMenu }) {
        ...Menu
      }
    content: wpPage(id: { eq: $id }) {
        ...Page
      }
      announcement: allWpAnnouncement(limit: 1, sort: {fields: date, order: ASC}) {
        nodes {
          ...Announcement
        }
      }
  }
`;
